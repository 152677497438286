import { useEffect, useState } from "react";
// store
import useStore from "@services/store/store";
// components
import CallItem from "./CallItem/CallItem";
// css
import "./callList.css";
import Header from "../Header/Header";

export default function CallList() {
  // parse list of calls from local storage
  const { calls, setCalls } = useStore((state) => state);

  useEffect(() => {
    const calls = JSON.parse(localStorage.getItem("waiterCall")) || [];
    setCalls(calls);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="call-list">
      <CallItem calls={calls} />
      <Header />
    </div>
  );
}
