import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// axios
import axios from "axios";
// api
import { endpoints } from "@api/endpoints";
// react-router-dom
import { useLocation } from "react-router-dom";
// store
import useStore from "@services/store/store";
// helpers
import fetchData from "@helpers/fetchData";
// scss
import "./header.scss";

export default function Header() {
  const navigate = useNavigate();

  const { setUser, setOrders } = useStore((state) => state);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tableId = queryParams.get("table_id");

  async function getme(token) {
    try {
      const response = await fetchData({
        path: endpoints.user.getme,
        method: "GET",
        auth: true,
      });

      if (response && response.data) {
        setUser({
          ...response.data.success,
          token,
        });
        // Перенаправить на главную страницу
        navigate("/");
      } else {
        console.error("Login failed: No data received");
      }
    } catch (error) {
      console.error("Login error:", error);
    }
  }

  useEffect(() => {
    fetchOrders();

    const token = localStorage.getItem("token");

    if (token) {
      getme(token);
    }

    // eslint-disable-next-line
  }, []);

  // get current path last after /
  const path = location.pathname.split("/").pop();

  function logout() {
    setUser(null);
    localStorage.removeItem("token");
    navigate("/login");
  }

  // function to send api call

  async function fetchOrders() {
    if (!tableId) return;
    axios({
      url: `${process.env.REACT_APP_API_URL}table/orders/${tableId}`,
      method: "POST",
    })
      .then((response) => {
        if (response?.data?.data) {
          setOrders(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <>
      {path === "qr-code" ? (
        <div></div>
      ) : (
        <div className="navbar bg-[#a5141a] py-[10px] ">
          <div>
            <div className="flex flex-row items-center justify-between">
              <div className="h-[44px] flex flex-col justify-center">
                <h3 className="text-white text-[20px] font-bold">
                  Zim-zim Waiter Call
                </h3>
              </div>
              <div class="marquee-container">
                <div class="marquee">
                  <span>Bilsang bilarsan, Zimzim eng zo'ri :-)</span>
                </div>
              </div>
              <div>
                <button
                  className="bg-[#fff] text-[#a5141a] px-[20px] py-[10px] rounded-[12px] font-bold"
                  onClick={() => {
                    logout();
                  }}
                >
                  Выход
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
