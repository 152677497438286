import { useEffect, useState } from "react";
// store
import useStore from "@services/store/store";

import "./style.scss";

export default function CallItem({ calls }) {
  const { setCalls } = useStore((state) => state);
  const [timers, setTimers] = useState({});
  // const middleIndex = Math.ceil(calls.length / 2);
  // const firstPart = calls.slice(0, middleIndex);
  // const secondPart = calls.slice(middleIndex);

  useEffect(() => {
    const savedTimers = JSON.parse(localStorage.getItem("timers")) || {};
    const initialTimers = {};

    calls?.forEach((call) => {
      const callId = call?.item?.id;
      initialTimers[callId] = savedTimers[callId] ?? 120; // Default to 2 minutes
    });

    setTimers(initialTimers);
  }, [calls]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimers((prevTimers) => {
        const updatedTimers = { ...prevTimers };
        let updatedCalls = [...calls];

        Object.keys(prevTimers).forEach((id) => {
          if (prevTimers[id] > 1) {
            updatedTimers[id] -= 1;
          } else {
            // Remove call when timer reaches 0
            updatedCalls = updatedCalls.filter(
              (call) => call?.item?.id !== parseInt(id)
            );
            delete updatedTimers[id]; // Remove timer for completed call
          }
        });

        // Save updated timers and calls to localStorage
        localStorage.setItem("timers", JSON.stringify(updatedTimers));
        localStorage.setItem("waiterCall", JSON.stringify(updatedCalls));
        setCalls(updatedCalls);

        return updatedTimers;
      });
    }, 1000); // Update every second

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, [calls]);
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     const updatedCalls = calls.reduce((acc, call) => {
  //       const callId = call?.item?.id;
  //       if (!timers[callId]) {
  //         timers[callId] = 120;
  //       }

  //       if (timers[callId] > 1) {
  //         timers[callId] -= 1;
  //         acc.push(call);
  //       } else {
  //         delete timers[callId];
  //       }
  //       return acc;
  //     }, []);

  //     localStorage.setItem("waiterCall", JSON.stringify(updatedCalls));
  //     setCalls(updatedCalls);

  //     setTimers({ ...timers });
  //   }, 500);

  //   return () => clearInterval(intervalId);
  // }, [calls]);

  function acceptCall(callId) {
    const savedCalls = JSON.parse(localStorage.getItem("waiterCall")) || [];
    const newCalls = savedCalls.filter((c) => c.item?.id !== callId);

    localStorage.setItem("waiterCall", JSON.stringify(newCalls));

    setCalls(newCalls);
    setTimers((prevTimers) => {
      const updatedTimers = { ...prevTimers };
      delete updatedTimers[callId];
      return updatedTimers;
    });
  }

  function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  }

  const firstPart = calls.slice(0, 6); // First table with 6 items
  const secondPart = calls.slice(6);
  return (
    <div className="call-container">
      <div className="table">
        <div className="column">
          <div className="header">
            <div>
              <p>STOL </p>
            </div>
            <div>
              <p>JOY NOMI</p>
            </div>
            <div>
              <p>OFITSIANT</p>
            </div>
            <div>
              <p>TIME</p>
            </div>
          </div>
          {firstPart.map((call, index) => (
            <div className="row" key={call?.item?.id || index}>
              <div>
                <p>{call?.item?.data?.table_number || "N/A"}</p>
              </div>
              <div className="truncate">
                {call?.item?.data?.place || "место не указано"}
              </div>
              <div className="truncate">{call?.item?.data?.waiter || "--"}</div>
              <div>
                <p>{formatTime(timers[call?.item?.id] || 0)}</p>
              </div>
            </div>
          ))}
        </div>

        <div className="column">
          <div className="header">
            <div>
              <p>STOL</p>
            </div>
            <div>
              <p>JOY NOMI</p>
            </div>
            <div>
              <p>OFITSIANT</p>
            </div>
            <div>
              <p>TIME</p>
            </div>
          </div>
          {secondPart.map((call, index) => (
            <div className="row" key={call?.item?.id || index}>
              <div>
                <p>{call?.item?.data?.table_number || "N/A"}</p>
              </div>
              <div className="truncate">
                {call?.item?.data?.place || "место не указано"}
              </div>
              <div className="truncate">{call?.item?.data?.waiter || "--"}</div>
              <div>
                <p>{formatTime(timers[call?.item?.id] || 0)}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
  // return (
  //   <div className="call-table-container">
  //     <div className="table ">
  //       <div className="call-table">
  //         <div className="call-header">
  //           <p>T/R</p>
  //           <p>STOL RAQAMI</p>
  //           <p>JOY NOMI</p>
  //           <p>OFITSIANT</p>
  //           <p>TIME</p>
  //         </div>
  //         {firstPart.map((call, index) => (
  //           <div key={call?.item?.id || index} className="call-row">
  //             <p>{index + 1 + firstPart.length}</p>
  //             <p>{call?.item?.data?.table_number || "N/A"}</p>
  //             <p>{call?.item?.data?.place || "место не указано"}</p>
  //             <p>{call?.item?.data?.waiter || "--"}</p>
  //             <p>
  //               {timers[call?.item?.id]
  //                 ? formatTime(timers[call?.item?.id])
  //                 : "N/A"}
  //             </p>
  //           </div>
  //         ))}
  //       </div>
  //       {/* <table className="call-table">
  //         <thead>
  //           <tr>
  //             <th>T/R</th>
  //             <th>STOL RAQAMI</th>
  //             <th>JOY NOMI</th>
  //             <th>OFITSIANT</th>
  //             <th>TIME</th>
  //           </tr>
  //         </thead>
  //         <tbody>
  //           {firstPart.map((call, index) =>
  //             call.expired ? (
  //               <tr key={index} className="expired-row">
  //                 <td colSpan="6" className="text-center">
  //                   This row has expired.
  //                 </td>
  //               </tr>
  //             ) : (
  //               <tr key={call?.item?.id || index} className={"new-row"}>
  //                 <td>{index + 1}</td>
  //                 <td>{call?.item?.data?.table_number || "N/A"}</td>
  //                 <td>{call?.item?.data?.place || "место не указано"}</td>
  //                 <td>{call?.item?.data?.waiter || "--"}</td>
  //                 <td>
  //                   {timers[call?.item?.id]
  //                     ? formatTime(timers[call?.item?.id])
  //                     : "N/A"}
  //                 </td>
  //               </tr>
  //             )
  //           )}
  //         </tbody>
  //       </table> */}
  //       <div className="call-table">
  //         <div className="call-header">
  //           <p>T/R</p>
  //           <p>STOL RAQAMI</p>
  //           <p>JOY NOMI</p>
  //           <p>OFITSIANT</p>
  //           <p>TIME</p>
  //         </div>
  //         {secondPart.map((call, index) => (
  //           <div key={call?.item?.id || index} className="call-row">
  //             <p>{index + 1 + firstPart.length}</p>
  //             <p>{call?.item?.data?.table_number || "N/A"}</p>
  //             <p>{call?.item?.data?.place || "место не указано"}</p>
  //             <p>{call?.item?.data?.waiter || "--"}</p>
  //             <p>
  //               {timers[call?.item?.id]
  //                 ? formatTime(timers[call?.item?.id])
  //                 : "N/A"}
  //             </p>
  //           </div>
  //         ))}
  //       </div>

  //       {/* <table className="call-table">
  //         <thead>
  //           <tr>
  //             <th>T/R</th>
  //             <th>STOL RAQAMI</th>
  //             <th>JOY NOMI</th>
  //             <th>OFITSIANT</th>
  //             <th>TIME</th>
  //           </tr>
  //         </thead>
  //         <tbody>
  //           {secondPart.map((call, index) =>
  //             call.expired ? (
  //               <tr key={index} className="expired-row">
  //                 <td colSpan="6" className="text-center">
  //                   This row has expired.
  //                 </td>
  //               </tr>
  //             ) : (
  //               <tr key={call?.item?.id || index} className={"new-row"}>
  //                 <td>{index + 7}</td>
  //                 <td>{call?.item?.data?.table_number || "N/A"}</td>
  //                 <td>{call?.item?.data?.place || "место не указано"}</td>
  //                 <td>{call?.item?.data?.waiter || "--"}</td>
  //                 <td>
  //                   {timers[call?.item?.id]
  //                     ? formatTime(timers[call?.item?.id])
  //                     : "N/A"}
  //                 </td>
  //               </tr>
  //             )
  //           )}
  //         </tbody>
  //       </table> */}
  //     </div>
  //   </div>
  // );
}

// import { useEffect, useRef, useState } from "react";
// // store
// import useStore from "@services/store/store";

// import "./style.scss";

// export default function CallItem({ call }) {
//   const { setCalls } = useStore((state) => state);
//   const cardRef = useRef(null);
//   const [timeLeft, setTimeLeft] = useState(12);

//   function acceptCall() {
//     // remove call from local storage
//     const calls = JSON.parse(localStorage.getItem("waiterCall")) || [];
//     const newCalls = calls.filter((c) => c.item?.id !== call?.item?.id);

//     localStorage.setItem("waiterCall", JSON.stringify(newCalls));

//     // set calls to store
//     setCalls(newCalls);
//   }

//   // useEffect(() => {
//   //   if (timeLeft === 0) {
//   //     acceptCall(); // Remove the call when the timer hits zero
//   //     return;
//   //   }

//   //   const timerId = setInterval(() => {
//   //     setTimeLeft((prevTime) => prevTime - 1);
//   //   }, 1000);

//   //   return () => clearInterval(timerId); // Cleanup on unmount
//   // }, [timeLeft === 0]);

//   // Prevent rendering if the timer is complete and the call is removed

//   return (
//     <div ref={cardRef} className={`call-item-container `}>
//       <h1 className="call-item-text">
//         {call?.item?.data?.table_number || "0"}{" "}
//       </h1>
//       <p className="call-item-place">
//         {call?.item?.data?.place || "место не указано"}
//       </p>
//       <button className="call-item-accept" onClick={() => acceptCall()}>
//         <svg
//           xmlns="http://www.w3.org/2000/svg"
//           x="0px"
//           y="0px"
//           width="50"
//           height="50"
//           viewBox="0 0 50 50"
//           className="accept-icon"
//         >
//           <path
//             fill="#fff"
//             d="M 42.964844 8.6894531 A 1.0001 1.0001 0 0 0 42.160156 9.1582031 L 21.699219 40.867188 L 7.671875 28.158203 A 1.0004048 1.0004048 0 1 0 6.328125 29.640625 L 21.228516 43.140625 A 1.0001 1.0001 0 0 0 22.740234 42.941406 L 43.839844 10.242188 A 1.0001 1.0001 0 0 0 42.964844 8.6894531 z"
//           ></path>
//         </svg>
//       </button>
//     </div>
//   );
// }
