import { useEffect, useState } from "react";
// store
import useStore from "@services/store/store";
// socket.io
import { io } from "socket.io-client";
import Modal from "react-modal";
import "./style.css";

export default function Socket() {
  const { user, setCalls } = useStore((state) => state);
  const [isModal, setIsModal] = useState(true);
  const [eventQueue, setEventQueue] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    if (!user) return;

    const url = process.env.REACT_APP_SOCKET_URL;

    const socket_organization_id = user?.organization_id;

    const socket = io(`${url}?organization_id=${socket_organization_id}`);

    socket.on("connect", () => {
      socket.emit("joinRoom", `room:${socket_organization_id}`);
    });

    socket.on("disconnect", (reason) => {
      console.log("disconnect", reason);
    });

    // on error
    socket.on("error", (error) => {
      console.log("error: ", error);
    });

    socket.on("waiterCall", (data) => {
      setEventQueue((prevQueue) => [...prevQueue, data]);
    });

    return () => {
      socket.disconnect();
    };
  }, [user, setCalls]);

  useEffect(() => {
    if (isProcessing || eventQueue.length === 0) return;

    const processEvent = async () => {
      setIsProcessing(true);

      const data = eventQueue[0];

      const allVoices = data?.item?.data?.voices;
      if (allVoices) {
        const waiterLocal =
          JSON.parse(localStorage.getItem("waiterCall")) || [];
        localStorage.setItem(
          "waiterCall",
          JSON.stringify([...waiterLocal, data])
        );
        setCalls([...waiterLocal, data]);

        await playAudioWithContext(allVoices); // Ensure sequential playback
      }

      setEventQueue((prevQueue) => prevQueue.slice(1)); // Remove the processed event
      setIsProcessing(false);
    };

    processEvent();
  }, [eventQueue, isProcessing]);

  const playAudioWithContext = async (soundsArray) => {
    for (const soundUrl of soundsArray) {
      try {
        await playSound(soundUrl);
      } catch (error) {
        console.error("Error playing sound:", soundUrl, error);
      }
    }
  };

  const playSound = (soundUrl) => {
    return new Promise((resolve, reject) => {
      const audio = new Audio(soundUrl);
      audio.volume = 1;

      audio.onended = resolve;
      audio.onerror = reject;
      audio.play().catch(reject);
    });
  };
  return (
    <div className="socket-modal">
      {/* <Modal ariaHideApp={false} isOpen={isModal} contentLabel="Example Modal">
        <div
          onClick={() => setIsModal(false)}
          className="flex flex-row items-center justify-center absolute top-0 right-0 m-[10px] cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="25"
            height="25"
            viewBox="0 0 50 50"
          >
            <path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z"></path>
          </svg>
        </div>
        <div className="box">
          <h1 className="title">
            Assalomu alaykum, bu yerda mijozlar chaqirgan stol raqami ko'rinadi{" "}
          </h1>
          <button
            className="button"
            onClick={() => {
              setIsModal(false);
            }}
          >
            Tushunarli
          </button>
        </div>
      </Modal> */}
    </div>
  );
}
